import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public me: BehaviorSubject<User> = new BehaviorSubject(null);
    public user: BehaviorSubject<User> = new BehaviorSubject(null);

    constructor(
        private http: HttpClient
    ) { }

    agreeToTermsAndPrivacy(token: string | null = null) {
        let url = '';
        if (token) {
            url = `${environment.magicUrl}/accept-terms`;
        } else {
            url = `${environment.api_url}/accept-terms`;
        }

        const body = { token };

        return this.http.post(url, body);
    }
}
